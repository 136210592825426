
export const rentcolumns = [
    {
        title: 'Дата обновления',
        dataIndex: 'created_at',
        type: 'date',
        key: '1',
    },
    {
        title: 'Услуга',
        dataIndex: 'department',
        key: '2',
    },
    {
        title: 'Страна выдачи',
        dataIndex: 'country1',
        type: 'string',
        key: '3',
    },
    {
        title: 'Город выдачи',
        dataIndex: 'city1',
        type: 'string',
        width: 150,
        key: '4',
    },
    {
        title: 'Страна сдачи',
        dataIndex: 'country2',
        type: 'string',
        key: '5',
    },
    {
        title: 'Город сдачи',
        dataIndex: 'city2',
        width: 150,
        type: 'string',
        key: '6',
    },
    {
        title: 'Кол-во КТК',
        dataIndex: 'col_ktk',
        type: 'range_integer',
        maxLength: 4,
        key: '7',
    },
    {
        title: 'Тип КТК',
        dataIndex: 'type_ktk',
        type: 'dropdown',
        key: '8',
    },
    {
        title: 'Ставка',
        dataIndex: 'bet',
        type: 'string',
        maxLength: 15,
        key: '9',
    },
    {
        title: 'Свободные дни',
        dataIndex: 'free_days',
        type: 'range_integer',
        maxLength: 4,
        key: '10',
    },
    {
        title: 'СНП',
        dataIndex: 'cnp',
        type: 'range_integer',
        maxLength: 4,
        key: '11',
    },
    {
        title: 'Комментарии',
        dataIndex: 'comment',
        type: 'string',
        maxLength: 4,
        key: '12',
        render: (col: any, item: any) => {
           return  item?.bits_comment?.[0]?.text;
        }
    }
];