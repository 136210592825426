import socket from "../socket";
import {RootState} from "../store";
import {getResources, setFetchedResources, setTypeContainers, setTypeDepartment, setTypeQuality} from "../store/bids";
import {Content} from "antd/es/layout/layout";
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";


const Layouts = (props: any) => {
    const dispatch = useDispatch();
    const hasFetchedResources = useSelector((state: RootState) => state.bids.hasFetchedResources);

    useEffect(() => {
        if (!hasFetchedResources) {
            dispatch(getResources());
            dispatch(setFetchedResources(true));
        }
        socket.on("return-resources", resResources);
        return () => {
            socket.off("return-resources", resResources);
        }
    }, []);

    const resResources = useCallback((response: any) => {
        dispatch(setTypeContainers(response.arContainers));
        dispatch(setTypeQuality(response.arQuality));
        dispatch(setTypeDepartment(response.arDepartment));
    }, [])
    return (
        <Content>{props.children}</Content>
    )
}

export default Layouts;