export const salecolumns = [
    {
        title: 'Дата обновления',
        dataIndex: 'created_at',
        type: 'date',
        key: '1',
    },
    {
        title: 'Услуга',
        dataIndex: 'department',
        key: '2',
    },
    {
        title: 'Страна выдачи',
        dataIndex: 'country1',
        type: 'string',
        key: '3',
    },
    {
        title: 'Город выдачи',
        dataIndex: 'city1',
        type: 'string',
        width: 150,
        key: '4',
    },
    {
        title: 'Тип КТК',
        dataIndex: 'type_ktk',
        type: 'dropdown',
        key: '5',
    },
    {
        title: 'Качество',
        dataIndex: 'quality',
        type: 'dropdown',
        key: '6',
    },
    {
        title: 'Ставка',
        dataIndex: 'bet',
        type: 'string',
        maxLength: 15,
        key: '7',
    },
    {
        title: 'Комментарии',
        dataIndex: 'comment',
        type: 'string',
        maxLength: 4,
        key: '8',
        render: (col: any, item: any) => {
            return  item?.bits_comment?.[0]?.text;
        }
    }
];