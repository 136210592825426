import React, {
  Key,
  useEffect,
  useState,
  CSSProperties,
  useCallback,
} from "react";
import { Checkbox, Flex, Pagination, Spin } from "antd";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { ColPage, ColPageMenu, Footer, TableWrapper } from "./styled";

export interface IColumnType<T> {
  key: Key;
  title?: string | null;
  dataIndex: string;
  subTitle?: string;
  date?: boolean;
  avatar?: string;
  checkbox?: boolean;
  link?: boolean;
  menu?: boolean;
  type?: string | undefined;
  width?: number | string;
  widthHeader?: number | string;
  repair?: boolean;
  maxLength?: number;
  render?: (column: IColumnType<T>, item: T) => JSX.Element | null;

  [key: number]: string;
}

export interface IHiddenColumn<T> {
  type?: string;
  column?: number;
}

export interface IPagination<T> {
  total?: number;
  current: number;
  pageSize: number;
  showSizeChanger?: boolean;
}

interface Props<T> {
  dataSource: T[];
  columns: IColumnType<T>[];
  style?: CSSProperties;
  pagination: IPagination<T>;
  onChangePagination?: (pagination: IPagination<T>) => void;
  onRow?: (record: T, rowIndex: number) => void;
  editRow?: (item: T) => void;
  deleteRow?: (row: T) => void;
  onClickLink?: (data: T) => void;
  height?: string;
  loading?: boolean;
  filtersFetch?: (text: string | number, column: IColumnType<any>) => void;
  filters?: any;
  handleCopyAll?: (selectedRows: any) => void;
  handleDeleteCopyAll?: (selectedRows: any, item: any) => void;
  onMouseTextRight?: [string, string];
  onMouseEditRight?: any;
  setColumnFilters?: (value: ((prevState: {}) => {}) | {}) => void;
  columnFilters?: any;
  selectAll?: (value: any) => Promise<void>;
  order?: (order: string) => void;
  setAllSelected?: any;
  allSelected?: any;
  setSelectedRows?: any;
  selectedRows?: any;
}

export function Table<T>({
  dataSource,
  columns,
  pagination,
  style,
  onChangePagination,
  onRow,
  editRow,
  deleteRow,
  onClickLink,
  height,
  loading,
  filtersFetch,
  filters,
  handleCopyAll,
  handleDeleteCopyAll,
  onMouseTextRight,
  onMouseEditRight,
  setColumnFilters,
  columnFilters,
  selectAll,
  order,
  setAllSelected,
  allSelected,
  setSelectedRows,
  selectedRows,
}: Props<T>) {
  const [hiddenColumns, setHiddenColumns] = useState<number[]>([]);
  const [sortedData, setSortedData] = useState<T[]>(dataSource);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [itemRows, setItemRows] = useState<T[]>([]);

  const onChangeCheck = useCallback(
    (index: number) => {
      setHiddenColumns(
        hiddenColumns.includes(index)
          ? hiddenColumns.filter((i) => i !== index)
          : [...hiddenColumns, index]
      );
    },
    [hiddenColumns]
  );

  const content = (
    <div>
      {columns.map(
        (column, index) =>
          column.dataIndex !== "hidden" && (
            <ColPageMenu key={index}>
              <Checkbox onChange={() => onChangeCheck(index)} /> {column.title}
            </ColPageMenu>
          )
      )}
    </div>
  );

  const handlePageChange = useCallback(
    (current: number, pageSize: number) => {
      if (onChangePagination) {
        onChangePagination({ ...pagination, current: current, pageSize });
      }
    },
    [onChangePagination, pagination]
  );

  useEffect(() => {
    setSortedData(dataSource);
  }, [dataSource]);

  const handleSelectAll = (checked: boolean) => {
    setAllSelected(checked);
    if (checked) {
      setSelectedRows(new Set(dataSource.map((item: any) => item.id)));
      if (selectAll) {
        selectAll(new Set(dataSource.map((item: any) => item.id)));
      }
    } else {
      setSelectedRows(new Set());
      if (selectAll) {
        selectAll(new Set());
      }
    }
    if (selectAll) {
    }
  };

  const handleRowSelect = (id: number, checked: boolean) => {
    const newSelectedRows = new Set(selectedRows);
    if (checked) {
      newSelectedRows.add(id);
    } else {
      newSelectedRows.delete(id);
    }
    setSelectedRows(newSelectedRows);
    setAllSelected(newSelectedRows.size === dataSource.length);
    if (selectAll) {
      selectAll(newSelectedRows);
    }
  };

  const handleDeleteAll = (selectedRows: any, item: any) => {
    setConfirmation(true);
    setItemRows(item);
  };

  const totalPages =
    Math.ceil(Number(pagination.total || 0) / pagination.pageSize) || 0;

  return (
    <div style={style}>
      <div
        style={{
          overflow: "scroll",
          height: height,
          scrollbarColor: "#b8b9b9 #dddddd",
        }}
      >
        <Spin tip="Загрузка..." spinning={loading}>
          <TableWrapper>
            <thead>
              <TableHeader
                pagination={pagination}
                columns={columns}
                hiddenColumn={hiddenColumns}
                content={content}
                filtersFetch={filtersFetch}
                filters={filters}
                order={order}
                onSelectAll={handleSelectAll}
                allSelected={allSelected}
                setColumnFilters={setColumnFilters}
                columnFilters={columnFilters}
              />
            </thead>
            <tbody>
              <TableRow
                deleteRow={deleteRow}
                editRow={editRow}
                data={sortedData}
                columns={columns}
                hiddenColumn={hiddenColumns}
                onRow={onRow}
                onClickLink={onClickLink}
                selectedRows={selectedRows}
                onMouseTextRight={onMouseTextRight}
                handleCopyAll={() =>
                  handleCopyAll ? handleCopyAll(selectedRows) : null
                }
                onMouseEditRight={onMouseEditRight}
                handleDeleteCopyAll={(item) =>
                  handleDeleteAll(selectedRows, item)
                }
                handleRowSelect={handleRowSelect}
              />
            </tbody>
          </TableWrapper>
        </Spin>
      </div>
      <Footer>
        <Flex
          justify={"space-between"}
          align={"flex-start"}
          style={{ width: "100%" }}
        >
          <ColPage>
            {pagination.current} страница из {totalPages}
          </ColPage>
          <div>
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handlePageChange}
              showSizeChanger={pagination.showSizeChanger}
              onShowSizeChange={handlePageChange}
              defaultPageSize={pagination.pageSize}
            />
          </div>
        </Flex>
      </Footer>
    </div>
  );
}
