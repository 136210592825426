import React, {useEffect, useState} from 'react';
import s from './header.module.scss';
import Icon from '@ant-design/icons';
import {
    DownloadIcon,
    ImageIcon,
    LogoIcon,
    RentLogo,
    SaleBigLogo,
    SaleLogo, TerminalsBigLogo,
    TerminalsLogo
} from "../../assets/images/svg";
import {Button, Dropdown, Flex, MenuProps, message, Space} from "antd";
import moment from "moment";
import {getAllBids, getAllBidsExcel, getFiltersBits, setBullService, setTypeTable} from "../../store/bids";
import {useDispatch} from "react-redux";
import socket from "../../socket";
import {DataAllBidsItem} from "../../interface";
import {jsPDF} from "jspdf";
import 'jspdf-autotable';
import {DownOutlined} from '@ant-design/icons';
import {SessionStorageManager} from "../TableComponent/sessionStorageManager";
import {useLocation} from "react-router-dom";
import * as XLSX from 'xlsx';

declare module 'jspdf' {
    interface jsPDF {
        autoTable: any;
    }
}

const Index: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState<number>(1);
    const [dropdownText, setDropdownText] = useState<string>('');
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: any = storageManager.getItem();

    const items: MenuProps['items'] = [
        {
            icon: <Icon component={RentLogo}/>,
            key: '1',
            label: 'Аренда',
            onClick: () => handleMenuClick('Аренда', 1),
        },
        {
            key: '2',
            label: 'Продажи',
            icon: <Icon component={SaleLogo}/>,
            onClick: () => handleMenuClick('Продажи', 2),
        },
        {
            key: '3',
            label: 'Терминалы',
            icon: <Icon component={TerminalsLogo}/>,
            onClick: () => handleMenuClick('Терминалы', 3),
        },
    ];

    const itemsDownload: MenuProps['items'] = [
        {key: '1', label: (<div onClick={() => handleDownload('excel')}>Скачать в Excel</div>)},
        {key: '2', label: (<div onClick={() => handleDownload('pdf')}>Скачать в PDF</div>)}
    ]

    const handleMenuClick = async (label: string, type: number) => {
        setDropdownText(label);
        dispatch(setTypeTable(type));
        dispatch(setBullService([]));
        sessionStorage.setItem('f'+ location.pathname, label);
        if (storageLocal){
            storageManager.updateItem({
                bull: storageLocal.bull,
                arrFilters: {},
                pagination: {current: 1, page: 10, total: 0},
            });
        }else {
            storageManager.setItem({
                pagination: {current: 1, page: 10, total: 0},
                bull: storageLocal.bull,
                arrFilters: {},
            });
        }
        const getFiters: any = storageManager.getItem();
        if (getFiters) {
            getFiters.fiters[0] = {department: [label]};
            storageLocal.fiters[0] = {department: [label]};
            storageLocal.labelFilter = label
            storageManager.updateItem(storageLocal);
            filtersColumn(getFiters, 1, storageLocal?.pagination?.page || 10, 'DESC');
        }
    };

    useEffect(() => {
        if (storageLocal) {
            setDropdownText(storageLocal.labelFilter);
        }else {
            setDropdownText('Аренда');
        }
        if (!storageLocal){
            storageManager.setItem({
                fiters: [{department: ['Аренда']}],
                pagination: {current: 1, page: 10, total: 0},
                bull: [],
                labelFilter: 'Аренда'
            });
        }
        const handleResponse = (response: any) => {
            setLoading(false);
            resAllBids(response);
            setLoading(false);
        };
        socket.on('return-get-all-bids-excel', handleResponse);
        return () => {
            socket.off('return-get-all-bids-excel', handleResponse);
        };
    }, []);

    const downloadExcel = (response: any) => {
        if (response.rows.length > 0) {
            const rows = response.rows.map(
                ({created_at, department, country1, city1, country2, city2, col_ktk, quality, bet,
                     bet_1_day, bet_1_crane, free_days, cnp, comment,
                 }: DataAllBidsItem) =>
                    ({created_at: moment(created_at).format('DD.MM.YYYY'), department, country1, city1,
                        country2, city2, col_ktk, quality, bet, bet_1_day, bet_1_crane, free_days,
                        cnp, comment}));

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(rows);

            const colLengths = Object.keys(rows[0]).map((k) => k.toString().length);
            for (const d of rows) {
                Object.values(d).forEach((element, index) => {
                    if (element !== null && element !== undefined) {
                        const length = element.toString().length;
                        if (colLengths[index] < length) {
                            colLengths[index] = length;
                        }
                    }
                });
            }
            worksheet['!cols'] = colLengths.map((l, index) => {
                if (
                    index === 0 ||
                    index === 1 ||
                    index === 5 ||
                    index === 6 ||
                    index === 7 ||
                    index === 8 ||
                    index === 9
                ) {
                    return {
                        wch: 10,
                    };
                } else {
                    return {
                        wch: 20,
                    };
                }
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

            XLSX.utils.sheet_add_aoa(worksheet, [
                ["Дата обновления", "Услуга", "Страна выдачи", "Город выдачи", "Страна сдачи", "Город сдачи",
                    "Кол-во КТК", "Тип КТК", "Качество", "Ставка", "Ставка 1 кран", "Ставка 1 день", "Свободные дни", "СНП"],
            ]);

            XLSX.writeFile(workbook, 'Актуальные ставки MyContainers на ' + moment().format('DD.MM.YYYY') + '.xlsx', {compression: true});
        } else {
            message.info('Нет данных!')
        }
    }

    const resAllBids = (response: any) => {
        if (response.download_type === 'excel') {
            downloadExcel(response.data);
        } else {
            if (response.data.rows.length > 0) {
                const rows = response.data.rows.map(({
                                                    created_at, department, country1, city1, country2, city2,
                                                    type_ktk,col_ktk, quality, bet,bet_1_day, bet_1_crane, free_days, cnp,
                                                    bits_comment
                                                }: DataAllBidsItem) => ({
                    created_at: moment(created_at).format('DD.MM.YYYY'),
                    department,
                    country1,
                    city1,
                    country2,
                    city2,
                    type_ktk,
                    col_ktk,
                    quality,
                    bet,
                    bet_1_day,
                    bet_1_crane,
                    free_days,
                    cnp,
                    bits_comment
                }));
                const doc = new jsPDF('landscape');
                const myFont = "/PT_Sans-Web-Regular.ttf";
                doc.addFont(myFont, "PTSans", "normal");
                doc.setFont("PTSans");
                doc.setFontSize(22);

                // Настройки таблицы
                let head: string[] = []
                let tableData: any[] = [];
                const session: any = storageManager.getItem();
                if (session.labelFilter === 'Аренда') {
                    head = ["Дата обновления", "Услуга", "Страна выдачи", "Город выдачи", "Страна сдачи", "Город сдачи",
                        "Кол-во КТК", "Тип КТК", "Ставка", "Свободные дни", "СНП","Комментарии"];
                    tableData = rows.map((row: any) => [
                        row.created_at,
                        row.department,
                        row.country1,
                        row.city1,
                        row.country2,
                        row.city2,
                        row.col_ktk,
                        row.type_ktk,
                        row.bet,
                        row.free_days,
                        row.cnp,
                        row.bits_comment?.[0]?.text,
                    ]);
                }
                if (session.labelFilter === 'Продажи') {
                    head = ["Дата обновления", "Услуга", "Страна выдачи", "Город выдачи",
                            "Тип КТК", "Качество", "Ставка","Комментарии"];
                    tableData = rows.map((row: any) => [
                        row.created_at,
                        row.department,
                        row.country1,
                        row.city1,
                        row.type_ktk,
                        row.quality,
                        row.bet,
                        row.bits_comment?.[0]?.text,
                    ]);
                }
                if (session.labelFilter === 'Терминалы') {
                    head = ["Дата обновления","Услуга","Страна выдачи", "Город выдачи",
                            "Тип КТК", "Ставка 1 день",'Ставка 1 кран',"Комментарии"];
                    tableData = rows.map((row: any) => [
                        row.created_at,
                        row.department,
                        row.country1,
                        row.city1,
                        row.type_ktk,
                        row.bet_1_day,
                        row.bet_1_crane,
                        row.bits_comment?.[0]?.text,
                    ]);
                }
                const tableConfig = {
                    head: [head],
                    body: tableData,
                    theme: 'grid',
                    headStyles: {
                        lineWidth: 0.1,
                        fillColor: [255, 255, 255],
                        textColor: [21, 21, 21],
                        font: "PTSans",
                        fontStyle: 'bold',
                        halign: "center",
                    },
                    bodyStyles: {
                        fillColor: [255, 255, 255],
                        textColor: [21, 21, 21],
                        font: "PTSans"
                    },
                    textColor: 20,
                    margin: {top: 25, left: 10, right: 10},
                    styles: {fontSize: 10, fontStyle: 'italic'},
                    tableWidth: 'auto',
                    columnStyles: {
                        0: {halign: 'center'},
                        1: {halign: 'center'},
                        2: {halign: 'center'},
                        3: {halign: 'center'},
                        4: {halign: 'center'},
                        5: {halign: 'center'},
                        6: {halign: 'center'},
                        7: {halign: 'center'},
                        8: {halign: 'center'},
                        9: {halign: 'center'},
                        10: {halign: 'center'},
                        11: {halign: 'center'},
                    }
                };

                doc.text('Актуальные ставки MyContainers на ' + moment().format('DD.MM.YYYY'), 10, 10);
                doc.setFontSize(12).text(session.labelFilter, 10, 20);
                // Добавляем таблицу в PDF
                doc.autoTable(tableConfig);

                const y = 70; // Отступ от верхнего края

                const applyOpacityToImage = (imageSrc: string, opacity: number, callback: (dataUrl: string) => void) => {
                    const img = new Image();
                    img.src = imageSrc;

                    img.onload = function () {
                        const canvas = document.createElement('canvas');
                        const ctx: any = canvas.getContext('2d');

                        // Set canvas size to match the image
                        canvas.width = img.width;
                        canvas.height = img.height;

                        // Set opacity
                        ctx.globalAlpha = opacity;

                        // Draw image to canvas with the opacity
                        ctx.drawImage(img, 0, 0);

                        // Get the base64 data of the image with opacity applied
                        const dataUrl = canvas.toDataURL('image/png');

                        // Use this base64 data URL for the image in jsPDF
                        callback(dataUrl);
                    };
                };

                applyOpacityToImage('/mk.png', 0.5, (dataUrl) => {
                    const totalPages = doc.internal.pages.length;
                    for (let i = 1; i <= totalPages; i++) {
                        doc.setPage(i);
                        doc.addImage(dataUrl, 'PNG', 10, y, 250, 90);
                    }

                    doc.save('Ставки Мой Контейнер ' + moment().format('DD.MM.YYYY') + '.pdf');
                });
            }
        }
    }

    const handleDownload = (type: string) => {
        const getFiters: any = storageManager.getItem();
        setLoading(true);
        dispatch(getAllBidsExcel({order: 'DESC', department: getFiters.labelFilter, download_type: type}));
    }

    const filtersColumn = (data: any, current: number, page: number, order: string) => {
        if (storageLocal && storageLocal.pagination !== undefined) {
            storageLocal.pagination.current = current;
            storageLocal.pagination.page = page;
        }
        setCurrent(current);
        if (Object.keys(data).length > 0) {
            dispatch(
                getFiltersBits({
                    data: data.fiters,
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        } else {
            dispatch(
                getAllBids({
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        }
    };

    return (
        <Flex className={s.header__container} justify={"space-between"}>
            <Flex gap={16} align={"center"} justify={"space-between"} style={{width: '100%'}}>
                <Flex gap={6} style={{width: 200}}>
                    <Icon component={LogoIcon}/>
                    {dropdownText == 'Аренда' &&
                        <Icon component={ImageIcon}/>
                    }
                    {dropdownText == 'Продажи' &&
                        <Icon component={SaleBigLogo}/>
                    }
                    {dropdownText == 'Терминалы' &&
                        <Icon component={TerminalsBigLogo}/>
                    }
                    <Dropdown menu={{items, selectable: true, defaultSelectedKeys: ['1']}}
                              trigger={['click']} placement="bottomRight">
                        <Space style={{cursor: "pointer"}}>
                            {dropdownText}
                            <DownOutlined/>
                        </Space>
                    </Dropdown>
                </Flex>
                <Flex className={s.header__title}>
                    Актуальные ставки MyContainers на {moment().format('DD.MM.YYYY')}
                </Flex>
                <Dropdown menu={{items: itemsDownload }} placement="bottomRight">
                    <Button icon={<DownloadIcon/>} loading={loading}/>
                </Dropdown>
            </Flex>
        </Flex>
    );
};

export default Index;