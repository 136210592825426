import React, { useEffect } from 'react';
import { Button, DatePicker, Form, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface ColumnDateProps {
  columnIndex: number;
  handleDateChange: (type: 'start' | 'end', date: Dayjs | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  handleChange: (
    option: string[],
    dates: [Date | null, Date | null],
    dataInt: [number | '' | null | undefined, number | '' | null | undefined],
    checked: boolean,
    columnIndex: number
  ) => void;
  startIntRange?: any;
  endIntRange?: any;
  columnFilters?: any;
  column: any;
}

const ColumnDate: React.FC<ColumnDateProps> = ({
  columnIndex,
  handleDateChange,
  handleChange,
  columnFilters,
  column,
  startIntRange,
  endIntRange,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (columnFilters && columnFilters.length > 0) {
      form.setFieldsValue({
        start: dayjs(columnFilters[0]) || null,
        end: dayjs(columnFilters[1]) || null,
      });
    } else {
      form.setFieldsValue({
        start: null,
        end: null,
      });
    }

  }, [form, columnFilters]);

  const clearDate = () => {
    form.resetFields();
    handleChange([], [null, null], [startIntRange, endIntRange], false, columnIndex);
  };

  const dateChange = (type: 'start' | 'end', date: Dayjs | null) => {
    handleDateChange(type, date);
  };

  const onFinish = (data: any) => {
    const start = data.start ? data.start.toDate() : null;
    const end = data.end ? data.end.toDate() : null;

    if (start && end) {
      handleChange(['date'], [start, end], [startIntRange, endIntRange], false, columnIndex);
    } else {
      message.info('Не указана дата!');
    }
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: 7 }}>
          <Form.Item name="start">
            <DatePicker
              onChange={(date) => dateChange('start', date)}
              style={{ width: 120 }}
              format="DD.MM.YYYY"
            />
          </Form.Item>
          <Form.Item name="end">
            <DatePicker
              onChange={(date) => dateChange('end', date)}
              style={{ width: 120 }}
              format="DD.MM.YYYY"
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 7 }}>
          <Button size="small" onClick={clearDate}>
            Сбросить
          </Button>
          <Button type="primary" size="small" htmlType="submit">
            ОК
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ColumnDate;
