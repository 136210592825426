import {configureStore} from "@reduxjs/toolkit/react";
import bids from "./bids";

export const store = configureStore({
    reducer: {
        bids
    },
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>

