import {io} from "socket.io-client";

const URL: any = process.env.REACT_APP_API_URL;

const socket = io(URL,
    {
        transports: ['websocket', 'polling'],
        forceNew: true,
        upgrade: false,
    });


export default socket;
