import Header from './components/Header/index'
import Main from './pages/main'
import Layouts from './components/Layouts';


const App: React.FC  = () => {
  return (
    <Layouts>
        <Header/>
        <Main/>
    </Layouts>
  );
}

export default App;
