import get from "lodash.get";
import { IColumnType, IHiddenColumn } from "./Table";
import {
  Avatar,
  Button,
  DropdownProps,
  Flex,
  Popover,
  Typography,
} from "antd";
import Icon, { UserOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { TableCell, TruncatedText } from "./styled";
import moment from "moment";
import { burgerIcon, RepairIcon } from "../../assets/images/svg";

interface Props<T> {
  item: any;
  column: IColumnType<T>;
  itemIndex: number;
  date?: boolean;
  link?: boolean;
  hiddenColumn: IHiddenColumn<T>;
  onRow?: (record: any, rowIndex: number) => void;
  editRow?: (item: any) => void;
  deleteRow?: (row: any) => void;
  onClickLink?: ((data: any) => void) | undefined;
  isChecked: boolean;
  onCheck: (checked: boolean) => void;
  handleCopyAll?: (() => void) | undefined;
  onMouseTextRight?: [string, string];
  handleDeleteCopyAll?: () => void;
}

export function TableRowCell<T>({
  item,
  column,
  onRow,
  itemIndex,
  editRow,
  deleteRow,
  onClickLink,
  isChecked,
  onCheck,
  handleCopyAll,
  onMouseTextRight,
  handleDeleteCopyAll,
}: Props<T>): JSX.Element {
  const [openPopover, setOpenPopover] = useState(false);
  const [openDropdown, setDropdown] = useState(false);
  const title = get(item, column?.dataIndex);
  const refTitle = !column.date ? title : moment(title).format("DD.MM.YYYY");

  const subTitle = get(
    item,
    typeof column.subTitle !== "undefined" ? column.subTitle : ""
  );
  const avatar = get(
    item,
    typeof column.avatar !== "undefined" ? column.avatar : ""
  );
  const checkbox =
    typeof column.checkbox !== "undefined" ? column.checkbox : false;
  const menu = typeof column.menu !== "undefined" ? column.menu : false;

  const viewFunction = () => {
    setOpenPopover(false);
    if (onRow) {
      onRow(item, itemIndex);
    }
  };

  const handleEdit = (row: any) => {
    setOpenPopover(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    editRow ? editRow(row) : null;
  };

  const handleDelete = (row: any) => {
    setOpenPopover(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
  };

  const getAllValues = (obj: any) => {
    let values: any = [];
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        values = values.concat(getAllValues(obj[key]));
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  };

  const handleCopy = async (row: any, column: any) => {
    setOpenPopover(false);
    const values1 = getAllValues(row).join(", ");
    await navigator.clipboard.writeText(values1);
  };

  const content = (item: any, column: any) => (
    <div>
      <p style={{ cursor: "pointer", marginLeft: 20 }} onClick={viewFunction}>
        Посмотреть
      </p>
      <p
        style={{ cursor: "pointer", marginLeft: 20 }}
        onClick={() => handleCopy(item, column)}
      >
        Копировать данные
      </p>
      <p
        style={{ cursor: "pointer", marginLeft: 20 }}
        onClick={() => handleEdit(item)}
      >
        Редактировать
      </p>
      <p
        style={{ cursor: "pointer", marginLeft: 20 }}
        onClick={() => handleDelete(item)}
      >
        {!item.delete ? "Удалить" : "Снять пометку на удаление"}
      </p>
    </div>
  );

  const openContext: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (onMouseTextRight && onMouseTextRight[0] !== "") {
      if (info.source === "trigger" || nextOpen) {
        setDropdown(nextOpen);
      }
    }
    setOpenPopover(false);
  };

  const renderTitle = (
    type: string | undefined,
    refTitle: string | string[] | undefined
  ) => {
    if (type === "date" && refTitle) {
      return moment(refTitle).format("DD.MM.YYYY");
    }
    if (Array.isArray(refTitle) && type === "array") {
      return (
        <Flex vertical gap={2}>
          {refTitle
            ?.filter((text) => text)
            .map((item: string, i: number) => (
              <Typography.Paragraph key={i} ellipsis style={{ margin: 0 }}>
                {item}
              </Typography.Paragraph>
            ))}
        </Flex>
      );
    }
    return refTitle || "";
  };

  return (
    <>
        <TableCell style={{ width: column.width }}>
          {column.render ? (
            column.render(column, item)
          ) : (
            <Flex align={"center"} style={{ width: column.width }} gap={10}>
              <Flex align={"center"}>
                {menu ? (
                  <>
                    <Popover
                      placement="bottomLeft"
                      content={content(item, column)}
                      open={openPopover}
                      onOpenChange={handleOpenChange}
                      trigger="click"
                    >
                      <Button onClick={(e) => e.stopPropagation()} type="link">
                        <Icon component={burgerIcon} />
                      </Button>
                    </Popover>
                  </>
                ) : null}
                <Flex gap={6} align={"center"}>
                  <Flex>
                    {typeof column.avatar !== "undefined" ? (
                      avatar === null || avatar === undefined ? (
                        <Avatar size={36}>
                          {title
                            ?.substring(0, 2)
                            .toUpperCase()
                            .replace(/['"]+/g, "")}
                        </Avatar>
                      ) : (
                        <Avatar
                          size={36}
                          src={avatar}
                          icon={<UserOutlined />}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </Flex>
                  <Flex vertical style={{ width: column.width }}>
                    <TruncatedText>
                      {column.link ? (
                        <Typography.Link
                          ellipsis
                          style={{ color: "#0148D7" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onClickLink) {
                              onClickLink([
                                item,
                                { column: Number(column.key) },
                              ]);
                            }
                          }}
                        >
                          {refTitle}
                        </Typography.Link>
                      ) : (
                        renderTitle(column.type, refTitle)
                      )}
                      {column.dataIndex === "repair" &&
                        item.repair === true && <Icon component={RepairIcon} />}
                      <div>{subTitle}</div>
                    </TruncatedText>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </TableCell>
    </>
  );
}
