import React, {useCallback, useEffect, useMemo, useState} from "react";
import Icon, {PlusCircleOutlined} from "@ant-design/icons";
import {Flex, Popover} from "antd";
import {
    AddHiddenColumn,
    TableHeaderCell,
    TableHeaderCellColor,
} from "./styled";
import {IColumnType, IPagination} from "./Table";
import {
    burgerBlueIcon,
    RepairIcon,
    bullIconGreen,
    burgerBlueActiveIcon,
} from "../../assets/images/svg";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import ColumnsFilters from "./columnsFilters";
import {useLocation} from "react-router-dom";
import {SessionStorageManager} from "./sessionStorageManager";
import {StorageData} from "../../interface";
import {clearFiltersFetch} from "../../store/bids";

interface Props<T> {
    columns: IColumnType<T>[];
    content?: React.JSX.Element;
    sortData?: (column: IColumnType<T>) => void;
    hiddenColumn?: number[];
    order?: (sort: string) => void;
    filtersFetch?:
        | ((text: string | number, column: IColumnType<any>) => void)
        | undefined;
    filters?: any;
    onSelectAll: (checked: boolean) => void;
    allSelected: boolean;
    columnFilters?: any;
    setColumnFilters?: any;
    pagination: IPagination<T>;
}

export function TableHeader<T>({
                                   columns,
                                   content,
                                   hiddenColumn = [],
                                   order,
                                   filtersFetch,
                                   filters,
                                   onSelectAll,
                                   allSelected,
                                   columnFilters,
                                   setColumnFilters,
                                   pagination
                               }: Props<T>): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();
    const findCheck = columns.filter((el: IColumnType<T>) => el.checkbox !== undefined);
    const [openPopovers, setOpenPopovers] = useState<{ [key: number]: boolean }>({});
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startIntRange, setStartIntRange] = useState<string | null>("0");
    const [endIntRange, setEndIntRange] = useState<string | null>("0");
    const [toggle, setToggle] = useState<boolean>(true);
    const [bull, setBull] = useState<number[]>([]);
    const dataFilters: any = useSelector((state: RootState) => state.bids.dataFilters);
    const dataTypeQuality: any = useSelector((state: RootState) => state.bids.dataTypeQuality);
    const dataTypeDepartment: any = useSelector((state: RootState) => state.bids.dataTypeDepartment);
    const dataTypeContainers: any = useSelector((state: RootState) => state.bids.dataTypeContainers);
    const bullService: any = useSelector((state: RootState) => state.bids.bullService);
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: StorageData | null = storageManager.getItem();

    useEffect(() => {
       setBull(storageLocal?.bull ? storageLocal?.bull : []);
    }, []);

    const optionsFilter = useMemo(() => {
        return dataFilters; // Убираем фильтрацию, оставляем все контейнеры
    }, [dataFilters]);

    const searchFilter = useCallback(
        (row: string, column: any) => {
            if (row.length > 0 && filtersFetch) {
                filtersFetch(row, !toggle ? column.subTitle : column.dataIndex);
            }
        },
        [toggle]
    );

    const updateBullState = async (option: any, columnIndex: number) => {
        // Получаем текущее состояние bull
        const currentBull: any = await new Promise((resolve) => {
            setBull((prev) => {
                resolve(prev);
                return prev; // Возвращаем предыдущее состояние
            });
        });

        const updatedBull = option.length > 0
            ? Array.from(new Set([...currentBull, columnIndex]))
            : currentBull.filter((x: any) => x !== columnIndex);

        setBull(updatedBull);
        return updatedBull;
    };

    // Обработка изменения фильтров для каждой колонки
    const handleChange = async (
        option: any[], date: [any | null, any | null],
        dataInt: [number | "" | null | undefined, number | "" | null | undefined], checked: boolean,
        columnIndex: number
    ) => {
        // Установка дат
        if (date[0] === null && date[1] === null) {
            setStartDate(null);
            setEndDate(null);
        }

        // Установка диапазона целых чисел
        if (dataInt[0] === null && dataInt[1] === null) {
            setStartIntRange(null);
            setEndIntRange(null);
        }

        // Обновление состояния bull
        const resUpdateBull: any = await updateBullState(option, columnIndex);

        // Обновление фильтров
        if (setColumnFilters) {
            setColumnFilters((prev: any) => {
                const column = columns[columnIndex];
                let updatedFilters: any = {};

                // Обновление фильтров на основе типа колонки
                if (column.type === "range_integer" && dataInt[0] !== null && dataInt[1] !== null) {
                    updatedFilters = {...prev, [columnIndex]: dataInt};
                } else if (column.type === "date" && date[0] !== null && date[1] !== null) {
                    updatedFilters = {...prev, [columnIndex]: date};
                } else {
                    updatedFilters = {...prev, [columnIndex]: option};
                }

                const newFilters = columns.reduce((acc: any, column, index) => {
                    const columnFilters = updatedFilters[index];
                    if (columnFilters && columnFilters.length > 0) {
                        acc[column.dataIndex] = columnFilters;
                    }
                    return acc;
                }, {} as { [key: string]: string[] });

                // Обновление данных
                if (filters) {
                    filters([newFilters], 1, storageLocal?.pagination?.page || 10);
                }
                if (storageLocal) {
                    storageLocal.arrFilters = updatedFilters;
                    storageLocal.bull = resUpdateBull;
                    storageLocal.fiters = [storageLocal.fiters[0]]
                    storageLocal.fiters.push(newFilters);
                    storageManager.updateItem(storageLocal);
                }
                setColumnFilters((prev: any) => ({...prev, ...[newFilters]}));
                setOpenPopovers((prevState) => ({...prevState, [columnIndex]: false}));
                return updatedFilters;
            });
        }
    };

    const handleDateChange = (type: "start" | "end", date: any) => {
        const newStartDate = type === "start" ? date : startDate;
        const newEndDate = type === "end" ? date : endDate;
        // Обновляем состояние
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleOrder = (row: any, index: number) => {
        if (order) {
            order(row.key);
        }
        setOpenPopovers((prevState) => ({...prevState, [index]: false}));
    };

    const handleRangeInteger = (type: "start" | "end", data: any) => {
        const newStartDate =
            type === "start" ? data : startIntRange !== null ? startIntRange : 0;
        const newEndDate = type === "end" ? data : endIntRange;
        // Обновляем состояние
        setStartIntRange(newStartDate);
        setEndIntRange(newEndDate);
    };

    const clearAllFilters = (index: number) => {
        setBull([]);
        setStartDate(null);
        setEndDate(null);
        setStartIntRange("0");
        setEndIntRange("0");
        filters([{}], 1, pagination.pageSize, "DESC");

        const storageData: StorageData = {
            bull: [],
            fiters: [{department: storageLocal?.fiters[0].department}],
            arrFilters: []
        };
        storageManager.updateItem(storageData);

        if (setColumnFilters) {
            setColumnFilters(() => []);
        }
        setOpenPopovers((prevState) => ({...prevState, [index]: false}));
    };

    // Функция фильтрации для конкретной колонки
    const filter = useCallback(
        (column: IColumnType<T>, columnIndex: number) => (
            <ColumnsFilters
                dataType={column.dataIndex === "quality" ? dataTypeQuality :
                    column.dataIndex === "type_ktk" ? dataTypeContainers : dataTypeDepartment}
                column={column}
                columnFilters={columnFilters}
                columnIndex={columnIndex}
                searchFilter={searchFilter}
                handleChange={handleChange}
                startIntRange={startIntRange}
                endIntRange={endIntRange}
                setStartIntRange={setStartIntRange}
                setEndIntRange={setEndIntRange}
                startDate={startDate}
                endDate={endDate}
                optionsFilter={optionsFilter}
                handleDateChange={handleDateChange}
                handleRangeInteger={handleRangeInteger}
                handleOrder={handleOrder}
                bull={bull}
                setColumnFilters={setColumnFilters}
                clearAllFilters={clearAllFilters}
            />
        ),
        [
            dataFilters,
            searchFilter,
            columnFilters,
            startIntRange,
            endIntRange,
            startDate,
            endDate,
        ]
    ); // Добавляем columnFilters в зависимости

    const handleOpenChange = useCallback((index: number, visible: boolean) => {
        setOpenPopovers((prevState) => ({...prevState, [index]: visible}));
    }, []);

    const handleSelectAll = (e: any) => {
        onSelectAll(e);
    };

    const onClickRow = () => {
        setToggle(true);
        dispatch(clearFiltersFetch());
    };

    return (
        <tr>
            {columns.map(
                (column, columnIndex) =>
                    !hiddenColumn.includes(columnIndex) &&
                    (column.dataIndex !== "hidden" ? (
                        <TableHeaderCell
                            key={`table-head-cell-${columnIndex}`}
                            style={{width: column.width}}>
                            <Flex gap={10} align={"center"}>
                                <Flex gap={3}>
                                    <TableHeaderCellColor bgColor={bull.includes(columnIndex) ? 'white' : 'default'}>
                                        <Flex gap={4}>
                                            {column.dataIndex === "repair" ? (
                                                <Icon component={RepairIcon}/>
                                            ) : (
                               /*                 <Popover
                                                    placement="bottom"
                                                    content={filter(column, columnIndex)} // Передаем индекс колонки
                                                    open={openPopovers[columnIndex]}
                                                    onOpenChange={(visible) =>
                                                        handleOpenChange(columnIndex, visible)
                                                    }
                                                    trigger="click">*/
                                                    <Flex align={"center"} gap={5}>
                                                        {bull.includes(columnIndex) ? (
                                                            <Icon component={bullIconGreen}/>
                                                        ) : (
                                                            <div style={{width: 16}}/>
                                                        )}
                                                        <div style={{paddingRight: 20}}> {column.title} </div>
                                                      {/*  <Icon
                                                            component={!bull.includes(columnIndex) ? burgerBlueIcon : burgerBlueActiveIcon}
                                                            onClick={onClickRow}/>*/}
                                                    </Flex>
                                           /*     </Popover>*/
                                            )}
                                        </Flex>
                                    </TableHeaderCellColor>
                                </Flex>
                            </Flex>
                        </TableHeaderCell>
                    ) : (
                        <TableHeaderCell
                            key={`table-head-cell-${columnIndex}`}
                            style={{width: column.width}}
                        >
                            <AddHiddenColumn>
                                <Popover placement="bottomRight" content={content}>
                                    <PlusCircleOutlined/>
                                </Popover>
                            </AddHiddenColumn>
                        </TableHeaderCell>
                    ))
            )}
        </tr>
    );
}
