import { IColumnType } from './Table';
import { TableRowCell } from './TableRowCell';
import { TableRowItem } from './styled';
import React, { useState } from 'react';

interface Props<T> {
  data: T[],
  columns: IColumnType<T>[],
  hiddenColumn: any,
  onRow?: (record: any, rowIndex: number) => void,
  editRow?: (item: any) => void,
  deleteRow?: (row: any) => void,
  onClickLink?: ((data: any) => void) | undefined,
  setAllSelected?: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  selectedRows?: any,
  handleRowSelect: (id: number, checked: boolean) => void,
  handleCopyAll?: ((rowEdit: any) => void) | undefined,
  handleDeleteCopyAll?: (item: any) => void
  onMouseTextRight?: [string, string],
  onMouseEditRight?: any
}

export function TableRow<T>({
                              data,
                              columns,
                              hiddenColumn,
                              onRow,
                              editRow,
                              deleteRow,
                              onClickLink,
                              selectedRows,
                              handleRowSelect,
                              handleCopyAll,
                              handleDeleteCopyAll,
                              onMouseTextRight,
                              onMouseEditRight,
                            }: Props<T>): JSX.Element {
  const [isDoubleClick, setIsDoubleClick] = useState(false);

  const copyAll = (item: any) =>{
    if (handleCopyAll) {
      onMouseEditRight(item);
    }
  }

  const handleMouseDown = (e: any,item: any) => {
    if (onRow) {
      e.stopPropagation();
      if (e.button === 1) {
        if (handleCopyAll) {
          copyAll(item);
        }
      }
    }
  };

  const handleDoubleClick = (e: any,item: any,itemIndex: number) => {
    if (onRow) {
      e.stopPropagation();
      onRow(item, itemIndex);
    }
  };

  const handleMouseDownWithDebounce = (e: any, item: any) => {
    setIsDoubleClick(false);
    setTimeout(() => {
      if (!isDoubleClick) {
        handleMouseDown(e, item);
      }
    }, 300);
  };

  const handleDoubleClickHandler = (e: any,item: any,itemIndex: number) => {
    setIsDoubleClick(true);
    handleDoubleClick(e,item,itemIndex);
  };
  
  return (
    <>
      {data?.map((item: any, itemIndex) => (
          <TableRowItem key={`table-body-${itemIndex}`}
            onMouseDown={(e) => handleMouseDownWithDebounce(e,item)}
            onDoubleClick={(e) =>handleDoubleClickHandler(e,item,itemIndex)}
          >
          {columns.map((column, columnIndex) => (
            !hiddenColumn.includes(columnIndex) && (
              <TableRowCell
                key={`table-row-cell-${columnIndex}`}
                item={item}
                column={column}
                onClickLink={onClickLink}
                itemIndex={columnIndex}
                hiddenColumn={hiddenColumn}
                onRow={onRow}
                editRow={editRow}
                deleteRow={deleteRow}
                onMouseTextRight={onMouseTextRight}
                handleCopyAll={() => copyAll(item)}
                handleDeleteCopyAll={() => handleDeleteCopyAll ? handleDeleteCopyAll(item) : null}
                isChecked={selectedRows && selectedRows.has(item?.id)}
                onCheck={(checked) => handleRowSelect(item?.id, checked)}
              />
            )
          ))}
        </TableRowItem>
      ))}
    </>
  );
}
