import * as CryptoJS from 'crypto-js'

export class Utils {
    static defaultTable = () => {
        return Array.from({ length: 10 }, (_, index) => ({
            id: index + 1,
            avatar: null,
        }));
    };
    static decrypt(hex: any) {
        const bytes = CryptoJS.AES.decrypt(hex, '02aab0f70eca03ff2e2397cb91076262');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    static async encrypt(hex: string) {
        return CryptoJS.AES.encrypt(hex, '02aab0f70eca03ff2e2397cb91076262').toString();
    }
}
