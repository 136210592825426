import React from 'react';
import { Checkbox } from 'antd';

interface ColumnCheckboxProps {
  handleChange: (option: string[], dates: [Date | null, Date | null],
                 dataInt: [number | "" | null | undefined, number | '' | null | undefined],
                 checked: boolean, columnIndex: number) => void,
  columnIndex: number,
  startDate: Date | null,
  endDate: Date | null,
  startIntRange?: any,
  endIntRange?: any
}

const ColumnCheckbox = ({
                          handleChange,
                          columnIndex,
                          startDate,
                          endDate,
                          startIntRange,
                          endIntRange,
                        }: ColumnCheckboxProps) => {
  return (
    <Checkbox onChange={(e) =>
      handleChange(['checked'], [startDate, endDate],
        [startIntRange,endIntRange], e.target.checked, columnIndex)}>В ремонте</Checkbox>
  );
};

export default ColumnCheckbox;
